body {
    height: 100vh;
    overflow-x: auto;
    overflow-y: hidden;
}

#logo {
    height: 2.5rem;
}

#overlay {
    background-color: rgba(0, 0, 0, 0.85);
    min-height: 100vh;
    min-width: 100vw;
    z-index: 1000;
    overflow-y: auto;
    position: fixed;
}

@media (max-height: 1000px) {
    #overlay footer,
    #overlay header {
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
        height: 1px;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: 1px;
    }
}

#overlay header {
    max-height: 20vh;
}

#overlay footer {
    height: 2rem;
}

#dataset-list {
    max-height: 70vh;
}

#overlay > * {
    color: #eeeeee;
}

#ui-buttons {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1000;
}

#ui-buttons button:hover {
    opacity: 100 !important;
}

#focus-panel {
    position: fixed;
    bottom: 0;
    left: 20vw;
    max-height: 20vh;
    width: 80vw;
    z-index: 100;
}

.head-sign {
    width: 40%;
}

.stop {
    width: 40%;
}

.vehicle-label {
    width: 20%;
}

#ui {
    max-height: 100vh;
    height: 100vh;
    width: 100vw;
}

#vehicle-list {
    z-index: 100;
    position: fixed;
    height: 100%;
    width: 20vw;
}

.scrollarea {
    overflow-y: auto;
}

.map-container {
    z-index: 0;
}
